// import avatar from '../../images/avatar.jpg';
// import { Avatar } from "@material-tailwind/react";
// import { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import api from '../../api/axiosConfig';

// export default function AccountList({ new_chat, messageDetails, setMessageDetails}) {
//     const [messageList, setMessageList] = useState([]);
//     const [newChat, setNewChat] = useState(new_chat);
//     const [intervalInitialized, setIntervalInitialized] = useState(false);
//     const [id, setId] = useState(null);
//     const [recipient, setRecipient] = useState(null);
//     const user = useSelector((state) => state.user);
//     const isAdmin = user && user.user_type == "Admin";


//     useEffect(() => {
//         if (newChat) {
//             setMessageDetails({
//                 roomId: isAdmin ? newChat.username : newChat.order_number,
//                 recipient: newChat.email,
//             });
//             return;
//         }
//     }, []);

//     useEffect(() => {
//         if (newChat) {
//             setMessageList([newChat]);
//         }

//         const fetchAccounts = () => {
//             api
//                 .get('/api/accounts-list/')
//                 .then((response) => {
//                     if (newChat) {
//                         //if response.data contains newChat, then don't add it
//                         //else add it
//                         var found = false;
//                         for (var i = 0; i < response.data.length; i++) {
//                             if (response.data[i].order_number == newChat.order_number) {
//                                 found = true;
//                                 break;
//                             }
//                         }
//                         if (!found) {
//                             setMessageList([...response.data, newChat]);
//                             setNewChat(null);
//                         }
//                         else {
//                             setMessageList(response.data);
//                             setNewChat(null);
//                         }
//                     }
//                     else {
//                         setMessageList(response.data);
//                     }
//                 })
//                 .catch((error) => {
//                     console.error('Error fetching user data:', error);
//                 });
//         }
//         if (!intervalInitialized) {
//             fetchAccounts();
//             if (!isAdmin) {
//                 let xid = setInterval(fetchAccounts, 10000);
//                 setId(xid);
//             }
//             setIntervalInitialized(true);
//         }

//         return () => {
//             if (id) {
//                 clearInterval(id);
//             }
//         }
//     }, []);


//     const isSelected = (message) => {
//         if (!messageDetails) {
//             return false;
//         }
//         if (isAdmin && message.username == messageDetails.roomId) {
//             return true;
//         }
//         else if (!isAdmin && message.order_number == messageDetails.roomId) {
//             return true;
//         }
//         return false;
//     }



//     return (
//         <ul className="flex-grow overflow-y-auto">
//             {Array.isArray(messageList) && messageList.length > 0 ? (
//                 messageList.map((message, ind) => (
//                     <li
//                         key={ind}
//                         className={`flex items-center py-3 px-4 cursor-pointer` + (isSelected(message) ? " bg-gray-200" : "")}
//                         onClick={() => {
//                             setMessageDetails({
//                                 roomId: isAdmin ? message.username : message.order_number,
//                                 recipient: message.email,
//                             });
//                         }}
//                     >
//                         <div className="flex-shrink-0 mr-3 mt-1">
//                             <Avatar
//                                 src={avatar}
//                                 alt="User Avatar"
//                                 size="md"
//                                 className="rounded-full w-12 h-12 object-cover"
//                             />
//                         </div>
//                         <div className="flex-grow">
//                             <h3 className="text-start ms-3 text-lg font-semibold">
//                                 {isAdmin && message.username}
//                                 {!isAdmin && (message.order_number == "SUPPORT" ? "SUPPORT" : "Order " + message.order_number)}
//                             </h3>
//                         </div>
//                         {/* You can add online/offline status indicators here */}
//                     </li>
//                 ))
//             ) : (
//                 <p className="p-4 text-gray-500">No recipients found.</p>
//             )}
//         </ul>
//     );
// }



import avatar from '../../images/avatar.jpg';
import { Avatar } from "@material-tailwind/react";
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import api from '../../api/axiosConfig';

export default function AccountList({ new_chat, messageDetails, setMessageDetails }) {
    const [messageList, setMessageList] = useState([]);
    const [newChat, setNewChat] = useState(new_chat);
    const [intervalInitialized, setIntervalInitialized] = useState(false);
    const [id, setId] = useState(null);
    const [searchQuery, setSearchQuery] = useState(""); // New state for search query
    const [filteredList, setFilteredList] = useState([]);
    const user = useSelector((state) => state.user);
    const isAdmin = user && user.user_type === "Admin";

    useEffect(() => {
        if (newChat) {
            setMessageDetails({
                roomId: isAdmin ? newChat.username : newChat.order_number,
                recipient: newChat.email,
            });
            return;
        }
    }, [newChat, isAdmin, setMessageDetails]);

    useEffect(() => {
        const fetchAccounts = () => {
            api
                .get('/api/accounts-list/')
                .then((response) => {
                    if (newChat) {
                        // Check if newChat should be added to the list
                        const found = response.data.some(account => account.order_number === newChat.order_number);
                        if (!found) {
                            setMessageList([...response.data, newChat]);
                        } else {
                            setMessageList(response.data);
                        }
                        setNewChat(null);
                    } else {
                        setMessageList(response.data);
                    }
                })
                .catch((error) => {
                    console.error('Error fetching user data:', error);
                });
        };

        if (!intervalInitialized) {
            fetchAccounts();
            if (!isAdmin) {
                const xid = setInterval(fetchAccounts, 10000);
                setId(xid);
            }
            setIntervalInitialized(true);
        }

        return () => {
            if (id) {
                clearInterval(id);
            }
        };
    }, [newChat, isAdmin, intervalInitialized, id]);

    useEffect(() => {
        // Filter the list based on searchQuery
        const lowercasedQuery = searchQuery.toLowerCase();
        const filtered = messageList.filter(message =>
            message.username.toLowerCase().includes(lowercasedQuery) ||
            message.email.toLowerCase().includes(lowercasedQuery)
            // message.order_number.toString().includes(lowercasedQuery)
        );
        setFilteredList(filtered);
    }, [searchQuery, messageList]);

    const isSelected = (message) => {
        if (!messageDetails) {
            return false;
        }
        if (isAdmin && message.username === messageDetails.roomId) {
            return true;
        } else if (!isAdmin && message.order_number === messageDetails.roomId) {
            return true;
        }
        return false;
    };

    return (
        <div>
            <input
                type="text"
                placeholder="Search by email, username, or account ID"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="p-2 mb-4 border rounded"
            />
            <ul className="flex-grow overflow-y-auto">
                {Array.isArray(filteredList) && filteredList.length > 0 ? (
                    filteredList.map((message, ind) => (
                        <li
                            key={ind}
                            className={`flex items-center py-3 px-4 cursor-pointer` + (isSelected(message) ? " bg-gray-200" : "")}
                            onClick={() => {
                                setMessageDetails({
                                    roomId: isAdmin ? message.username : message.order_number,
                                    recipient: message.email,
                                });
                            }}
                        >
                            <div className="flex-shrink-0 mr-3 mt-1">
                                <Avatar
                                    src={avatar}
                                    alt="User Avatar"
                                    size="md"
                                    className="rounded-full w-12 h-12 object-cover"
                                />
                            </div>
                            <div className="flex-grow">
                                <h3 className="text-start ms-3 text-lg font-semibold">
                                    {isAdmin && message.username}
                                    {!isAdmin && (message.order_number === "SUPPORT" ? "SUPPORT" : "Order " + message.order_number)}
                                </h3>
                            </div>
                            {/* You can add online/offline status indicators here */}
                        </li>
                    ))
                ) : (
                    <p className="p-4 text-gray-500">No recipients found.</p>
                )}
            </ul>
        </div>
    );
}


