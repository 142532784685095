// import avatar from '../../images/avatar.jpg';
// import { Avatar } from "@material-tailwind/react";
// import { useState, useEffect, useRef } from 'react';
// import api from '../../api/axiosConfig';
// import { toast } from 'react-toastify';
// import { useSelector } from 'react-redux';


// function Message({ message, userData, scroll }) {
//     return (
//         <div
//             ref={scroll}
//             className={`flex ${message.sender === userData.email ? 'justify-end' : 'justify-start'
//                 } mb-2`}
//         >
//             <div
//                 className={`${message.sender === userData.email
//                     ? 'bg-blue-500 text-white self-end'
//                     : 'bg-gray-300 text-gray-800 self-start'
//                     } py-1 px-4 rounded-lg max-w-md`}
//             >
//                 <div className="flex items-center">
//                     {message.sender === userData.email ? (
//                         <>
//                             {message.content && (
//                                 <div className="mr-3 text-sm">{message.content}</div>
//                             )}
//                             <Avatar
//                                 src={avatar}
//                                 alt="avatar"
//                                 size="xs"
//                                 className='rounded-full h-4 w-4'
//                             />
//                         </>
//                     ) : (
//                         <>
//                             <Avatar
//                                 src={avatar}
//                                 alt="avatar"
//                                 size="xs"
//                                 className="mr-3 rounded-full h-4 w-4"
//                             />
//                             {message.content && (
//                                 <div>{message.content}</div>
//                             )}
//                         </>
//                     )}

//                 </div>
//                 <div className="text-xs text-gray-500 mt-1">
//                     {new Date(message.timestamp).toLocaleString([], {
//                         year: 'numeric',
//                         month: 'numeric',
//                         day: 'numeric',
//                         hour: '2-digit',
//                         minute: '2-digit',
//                     })}
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default function Messages({ messageDetails, userData, scroll, display }) {
//     const [messages, setMessages] = useState(null);
//     const [sending, setSending] = useState(false);
//     const user = useSelector((state) => state.user);
//     const isAdmin = user && user.user_type === "Admin";
//     const [lastId, setLastId] = useState(0);
//     //const notificationSound = useRef(new Audio('/notification.mp3'));

//     const roomId = messageDetails?.roomId;
//     const recipient = roomId === "SUPPORT" ? "writerbeaz@gmail.com" : messageDetails?.recipient;

//     useEffect(() => {
//         const fetchMessages = async () => {
//             if (sending) return;
//             await api
//                 .get(`/api/${roomId}/`)
//                 .then((response) => {
//                     const fetchedMessages = response.data;
//                     try {
//                         if (fetchedMessages.length > 0) {
//                             const lastMessage = fetchedMessages[fetchedMessages.length - 1];
//                             if (lastMessage.id !== lastId) {
//                                 setMessages(fetchedMessages);
//                                 setLastId(lastMessage.id);
//                                 //notificationSound.current.play();
//                             }
//                         } else {
//                             setMessages([]);
//                         }
//                     } catch (error) {
//                         console.error('Error:', error);
//                     }
//                 })
//                 .catch((error) => {
//                     console.error('Error:', error);
//                 });
//         }

//         if (messages == null && roomId) {
//             fetchMessages();
//         }

//         let isFetching = false;

//         if (roomId === "SUPPORT") {
//             if (roomId && display !== 'none' && (window.msgSupportIntervals?.length <= 0 || !window.msgSupportIntervals)) {
//                 let xid = setInterval(async () => {
//                     if (!isFetching) {
//                         isFetching = true;
//                         fetchMessages()
//                             .then(() => {
//                                 isFetching = false;
//                             })
//                     }

//                 }, 5000);
//                 try {
//                     window.msgSupportIntervals.push(xid);
//                 } catch (e) {
//                     window.msgSupportIntervals = [];
//                     window.msgSupportIntervals.push(xid);
//                 }
//             }
//         } else {
//             if (roomId && display !== 'none' && (window.msgIntervals?.length <= 0 || !window.msgIntervals)) {
//                 let xid = setInterval(async () => {
//                     if (!isFetching) {
//                         isFetching = true;
//                         fetchMessages()
//                             .then(() => {
//                                 isFetching = false;
//                             })
//                     }

//                 }, 5000);
//                 try {
//                     window.msgIntervals.push(xid);
//                 } catch (e) {
//                     window.msgIntervals = [];
//                     window.msgIntervals.push(xid);
//                 }
//             }
//         }
//     }, [roomId, messages, sending]);

//     //scroll to bottom
//     useEffect(() => {
//         scroll.current?.scrollIntoView({ behavior: 'smooth' });
//     }, [messages, scroll]);



//     const sendMessage = (e) => {
//         if (!recipient) {
//             toast.error('Please select a recipient to chat with.', { duration: 5000 });
//             return;
//         }

//         const to_send_text = e.target.message.value;
//         if (!to_send_text || to_send_text.length === 0) {
//             toast.error('Please type a message.', { duration: 5000 });
//             return;
//         }

//         e.target.message.value = '';

//         const message = {
//             content: to_send_text,
//             sender: userData.user_id,
//             recipient: roomId === "SUPPORT" ? process.env.REACT_APP_SUPPORT_EMAIL || "writerbeaz@gmail.com" : recipient,
//         };
//         setSending(true);
//         api
//             .post(`/api/${getSendId()}/send/`, message)
//             .then((response) => {
//                 const newMessage = response.data;
//                 setMessages((prevMessages) => [...prevMessages, newMessage]);
//                 setSending(false);
//             })
//             .catch((error) => {
//                 const errorMessage = error.response?.data?.message || error.message || 'An unknown error occurred';
//                 //toast.error(`Error: ${errorMessage}`, { duration: 5000 });
//                 setSending(false);
//             });
//     };

//     const getSendId = () => {
//         if (isAdmin) {
//             return "SUPPORT";
//         }

//         return roomId;
//     }

//     useEffect(() => {
//         return () => {
//             setMessages(null);
//         }
//     }, [roomId]);



//     return (
//         <>
//             <div className={`px-6 overflow-y-auto ${roomId === "SUPPORT" && !isAdmin ? "h-36" : "h-[75%]"}`}>
//                 {/* Arrow back */}
//                 {!roomId && (
//                     <div className="text-center">Select a recipient to start chatting.</div>
//                 )}

//                 {messages?.length > 0 ? (
//                     messages.map((message, index) => (
//                         <Message
//                             key={index}
//                             message={message}
//                             userData={userData}
//                             scroll={scroll}
//                         />

//                     ))
//                 ) : (
//                     <>
//                         {messages == null && roomId ? (
//                             <div className="text-center">Fetching messages...</div>
//                         ) : (
//                             <>
//                                 {roomId && <div className="text-center">No messages yet.</div>
//                                 }
//                             </>
//                         )}
//                     </>
//                 )}
//             </div>

//             {roomId && <div className="bg-white">
//                 <form
//                     onSubmit={(e) => {
//                         e.preventDefault();
//                         sendMessage(e);
//                     }}
//                     className="flex flex-col space-x-2"
//                 >
//                     <textarea
//                         name="message"
//                         className="flex-grow border border-gray-300 rounded-lg px-4 py-2 focus:outline-none mb-2 resize-none"
//                         placeholder="Type a message..."
//                         onKeyDown={(e) => {
//                             if (e.key === 'Enter' && !e.shiftKey) {
//                                 e.preventDefault();
//                                 sendMessage(e);
//                             }
//                         }}
//                     />
//                     <button
//                         type="submit"
//                         className="bg-indigo-700 text-white px-4 py-2 rounded-lg"
//                     >
//                         Send
//                     </button>
//                 </form>
//             </div>}
//         </>
//     );
// }


// import avatar from '../../images/avatar.jpg';
// import { Avatar } from "@material-tailwind/react";
// import { useState, useEffect, useRef } from 'react';
// import api from '../../api/axiosConfig';
// import { toast } from 'react-toastify';
// import { useSelector } from 'react-redux';

// function Message({ message, userData, scroll }) {
//     return (
//         <div
//             ref={scroll}
//             className={`flex ${message.sender === userData.email ? 'justify-end' : 'justify-start'
//                 } mb-2`}
//         >
//             <div
//                 className={`${message.sender === userData.email
//                     ? 'bg-blue-500 text-white self-end'
//                     : 'bg-gray-300 text-gray-800 self-start'
//                     } py-1 px-4 rounded-lg max-w-md whitespace-pre-wrap`}
//             >
//                 <div className="flex items-center">
//                     {message.sender === userData.email ? (
//                         <>
//                             {message.content && (
//                                 <div className="mr-3 text-sm">{message.content}</div>
//                             )}
//                             <Avatar
//                                 src={avatar}
//                                 alt="avatar"
//                                 size="xs"
//                                 className='rounded-full h-4 w-4'
//                             />
//                         </>
//                     ) : (
//                         <>
//                             <Avatar
//                                 src={avatar}
//                                 alt="avatar"
//                                 size="xs"
//                                 className="mr-3 rounded-full h-4 w-4"
//                             />
//                             {message.content && (
//                                 <div>{message.content}</div>
//                             )}
//                         </>
//                     )}
//                 </div>
//                 <div className="text-xs text-gray-500 mt-1">
//                     {new Date(message.timestamp).toLocaleString([], {
//                         year: 'numeric',
//                         month: 'numeric',
//                         day: 'numeric',
//                         hour: '2-digit',
//                         minute: '2-digit',
//                     })}
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default function Messages({ messageDetails, userData, scroll, display }) {
//     const [messages, setMessages] = useState(null);
//     const [sending, setSending] = useState(false);
//     const user = useSelector((state) => state.user);
//     const isAdmin = user && user.user_type === "Admin";
//     const [lastId, setLastId] = useState(0);
//     const [messageText, setMessageText] = useState('');

//     const roomId = messageDetails?.roomId;
//     const recipient = roomId === "SUPPORT" ? "writerbeaz@gmail.com" : messageDetails?.recipient;

//     useEffect(() => {
//         const fetchMessages = async () => {
//             if (sending) return;
//             await api
//                 .get(`/api/${roomId}/`)
//                 .then((response) => {
//                     const fetchedMessages = response.data;
//                     try {
//                         if (fetchedMessages.length > 0) {
//                             const lastMessage = fetchedMessages[fetchedMessages.length - 1];
//                             if (lastMessage.id !== lastId) {
//                                 setMessages(fetchedMessages);
//                                 setLastId(lastMessage.id);
//                             }
//                         } else {
//                             setMessages([]);
//                         }
//                     } catch (error) {
//                         console.error('Error:', error);
//                     }
//                 })
//                 .catch((error) => {
//                     console.error('Error:', error);
//                 });
//         }

//         if (messages == null && roomId) {
//             fetchMessages();
//         }

//         let isFetching = false;

//         if (roomId === "SUPPORT") {
//             if (roomId && display !== 'none' && (window.msgSupportIntervals?.length <= 0 || !window.msgSupportIntervals)) {
//                 let xid = setInterval(async () => {
//                     if (!isFetching) {
//                         isFetching = true;
//                         fetchMessages()
//                             .then(() => {
//                                 isFetching = false;
//                             })
//                     }
//                 }, 5000);
//                 try {
//                     window.msgSupportIntervals.push(xid);
//                 } catch (e) {
//                     window.msgSupportIntervals = [];
//                     window.msgSupportIntervals.push(xid);
//                 }
//             }
//         } else {
//             if (roomId && display !== 'none' && (window.msgIntervals?.length <= 0 || !window.msgIntervals)) {
//                 let xid = setInterval(async () => {
//                     if (!isFetching) {
//                         isFetching = true;
//                         fetchMessages()
//                             .then(() => {
//                                 isFetching = false;
//                             })
//                     }
//                 }, 5000);
//                 try {
//                     window.msgIntervals.push(xid);
//                 } catch (e) {
//                     window.msgIntervals = [];
//                     window.msgIntervals.push(xid);
//                 }
//             }
//         }
//     }, [roomId, messages, sending]);

//     useEffect(() => {
//         if (messages && messages.length > 0) {
//             scroll.current?.scrollIntoView({ behavior: 'smooth' });
//         }
//     }, [messages, scroll]);

//     const sendMessage = (e) => {
//         e.preventDefault();

//         if (!recipient) {
//             toast.error('Please select a recipient to chat with.', { duration: 5000 });
//             return;
//         }

//         if (!messageText.trim()) {
//             toast.error('Please type a message.', { duration: 5000 });
//             return;
//         }

//         const message = {
//             content: messageText,
//             sender: userData.user_id,
//             recipient: roomId === "SUPPORT" ? process.env.REACT_APP_SUPPORT_EMAIL || "writerbeaz@gmail.com" : recipient,
//         };

//         setSending(true);
//         api
//             .post(`/api/${getSendId()}/send/`, message)
//             .then((response) => {
//                 const newMessage = response.data;
//                 setMessages((prevMessages) => [...prevMessages, newMessage]);
//                 setMessageText(''); // Clear textarea
//                 setSending(false);
//                 scroll.current?.scrollIntoView({ behavior: 'smooth' });
//             })
//             .catch((error) => {
//                 const errorMessage = error.response?.data?.message || error.message || 'An unknown error occurred';
//                 setSending(false);
//             });
//     };

//     const handleKeyDown = (e) => {
//         if (e.key === 'Enter' && !e.shiftKey) {
//             e.preventDefault();
//             e.target.form.dispatchEvent(new Event('submit', { cancelable: true }));
//         }
//     };

//     const getSendId = () => {
//         if (isAdmin) {
//             return "SUPPORT";
//         }
//         return roomId;
//     }

//     useEffect(() => {
//         return () => {
//             setMessages(null);
//         }
//     }, [roomId]);

//     return (
//         <>
//             <div className={`px-6 overflow-y-auto ${roomId === "SUPPORT" && !isAdmin ? "h-36" : "h-[75%]"}`}>
//                 {!roomId && (
//                     <div className="text-center">Select a recipient to start chatting.</div>
//                 )}

//                 {messages?.length > 0 ? (
//                     messages.map((message, index) => (
//                         <Message
//                             key={index}
//                             message={message}
//                             userData={userData}
//                             scroll={scroll}
//                         />
//                     ))
//                 ) : (
//                     <>
//                         {messages == null && roomId ? (
//                             <div className="text-center">Fetching messages...</div>
//                         ) : (
//                             <>
//                                 {roomId && <div className="text-center">No messages yet.</div>}
//                             </>
//                         )}
//                     </>
//                 )}
//             </div>

//             {roomId && <div className="bg-white">
//                 <form onSubmit={sendMessage} className="flex flex-col space-x-2">
//                     <textarea
//                         name="message"
//                         rows="3"
//                         value={messageText}
//                         onChange={(e) => setMessageText(e.target.value)}
//                         className="flex-grow border border-gray-300 rounded-lg px-4 py-2 focus:outline-none mb-2 resize-none"
//                         placeholder="Type a message..."
//                         onKeyDown={handleKeyDown}
//                     ></textarea>
//                     <button
//                         type="submit"
//                         className="bg-indigo-700 text-white px-4 py-2 rounded-lg"
//                         disabled={sending}
//                     >
//                         {sending ? 'Sending...' : 'Send'}
//                     </button>
//                 </form>
//             </div>}
//         </>
//     );
// }



import avatar from '../../images/avatar.jpg';
import { Avatar } from "@material-tailwind/react";
import { useState, useEffect, useRef } from 'react';
import api from '../../api/axiosConfig';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

function Message({ message, userData, scroll }) {
    return (
        <div
            ref={scroll}
            className={`flex ${message.sender === userData.email ? 'justify-end' : 'justify-start'
                } mb-2`}
        >
            <div
                className={`${message.sender === userData.email
                    ? 'bg-blue-500 text-white self-end'
                    : 'bg-gray-300 text-gray-800 self-start'
                    } py-1 px-4 rounded-lg max-w-md`}
            >
                <div className="flex items-center">
                    {message.sender === userData.email ? (
                        <>
                            {message.content && (
                                <pre className="mr-3 text-sm whitespace-pre-wrap break-words font-sans">{message.content}</pre>
                            )}
                            <Avatar
                                src={avatar}
                                alt="avatar"
                                size="xs"
                                className='rounded-full h-4 w-4'
                            />
                        </>
                    ) : (
                        <>
                            <Avatar
                                src={avatar}
                                alt="avatar"
                                size="xs"
                                className="mr-3 rounded-full h-4 w-4"
                            />
                            {message.content && (
                                <pre className="whitespace-pre-wrap break-words font-sans">{message.content}</pre>
                            )}
                        </>
                    )}

                </div>
                <div className="text-xs text-gray-500 mt-1">
                    {new Date(message.timestamp).toLocaleString([], {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                    })}
                </div>
            </div>
        </div>
    )
}

export default function Messages({ messageDetails, userData, scroll, display }) {
    const [messages, setMessages] = useState(null);
    const [sending, setSending] = useState(false);
    const user = useSelector((state) => state.user);
    const isAdmin = user && user.user_type === "Admin";
    const [lastId, setLastId] = useState(0);
    const [messageText, setMessageText] = useState('');

    const roomId = messageDetails?.roomId;
    const recipient = roomId === "SUPPORT" ? "writerbeaz@gmail.com" : messageDetails?.recipient;

    useEffect(() => {
        const fetchMessages = async () => {
            if (sending) return;
            await api
                .get(`/api/${roomId}/`)
                .then((response) => {
                    const fetchedMessages = response.data;
                    try {
                        if (fetchedMessages.length > 0) {
                            const lastMessage = fetchedMessages[fetchedMessages.length - 1];
                            if (lastMessage.id !== lastId) {
                                setMessages(fetchedMessages);
                                setLastId(lastMessage.id);
                            }
                        } else {
                            setMessages([]);
                        }
                    } catch (error) {
                        console.error('Error:', error);
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }

        if (messages == null && roomId) {
            fetchMessages();
        }

        let isFetching = false;

        if (roomId === "SUPPORT") {
            if (roomId && display !== 'none' && (window.msgSupportIntervals?.length <= 0 || !window.msgSupportIntervals)) {
                let xid = setInterval(async () => {
                    if (!isFetching) {
                        isFetching = true;
                        fetchMessages()
                            .then(() => {
                                isFetching = false;
                            })
                    }

                }, 5000);
                try {
                    window.msgSupportIntervals.push(xid);
                } catch (e) {
                    window.msgSupportIntervals = [];
                    window.msgSupportIntervals.push(xid);
                }
            }
        } else {
            if (roomId && display !== 'none' && (window.msgIntervals?.length <= 0 || !window.msgIntervals)) {
                let xid = setInterval(async () => {
                    if (!isFetching) {
                        isFetching = true;
                        fetchMessages()
                            .then(() => {
                                isFetching = false;
                            })
                    }

                }, 5000);
                try {
                    window.msgIntervals.push(xid);
                } catch (e) {
                    window.msgIntervals = [];
                    window.msgIntervals.push(xid);
                }
            }
        }
    }, [roomId, messages, sending]);

    //scroll to bottom
    useEffect(() => {
        scroll.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages, scroll]);



    const sendMessage = async (e) => {
        e.preventDefault();

        if (!recipient || !messageText.trim()) {
            return;
        }

        const message = {
            content: messageText,
            sender: userData.user_id,
            recipient: roomId === "SUPPORT" ? process.env.REACT_APP_SUPPORT_EMAIL || "writerbeaz@gmail.com" : recipient,
        };

        setSending(true);
        setMessageText(''); // Clear textarea immediately

        try {
            const response = await api.post(`/api/${getSendId()}/send/`, message);
            const newMessage = response.data;
            setMessages((prevMessages) => [...prevMessages, newMessage]);
            scroll.current?.scrollIntoView({ behavior: 'smooth' });
        } catch (error) {
            // Silently handle the error
        } finally {
            setSending(false);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            sendMessage(e);
        }
    };

    const getSendId = () => {
        if (isAdmin) {
            return "SUPPORT";
        }

        return roomId;
    }

    useEffect(() => {
        return () => {
            setMessages(null);
        }
    }, [roomId]);



    return (
        <>
            <div className={`px-6 overflow-y-auto ${roomId === "SUPPORT" && !isAdmin ? "h-36" : "h-[75%]"}`}>
                {/* Arrow back */}
                {!roomId && (
                    <div className="text-center">Select a recipient to start chatting.</div>
                )}

                {messages?.length > 0 ? (
                    messages.map((message, index) => (
                        <Message
                            key={index}
                            message={message}
                            userData={userData}
                            scroll={scroll}
                        />

                    ))
                ) : (
                    <>
                        {messages == null && roomId ? (
                            <div className="text-center">Fetching messages...</div>
                        ) : (
                            <>
                                {roomId && <div className="text-center">No messages yet.</div>
                                }
                            </>
                        )}
                    </>
                )}
            </div>

            {roomId && <div className="bg-white">
                <form onSubmit={sendMessage} className="flex flex-col space-x-2">
                    <textarea
                        name="message"
                        value={messageText}
                        onChange={(e) => setMessageText(e.target.value)}
                        onKeyDown={handleKeyDown}
                        className="flex-grow border border-gray-300 rounded-lg px-4 py-2 focus:outline-none mb-2 resize-none whitespace-pre-wrap"
                        placeholder="Type a message..."
                        rows="3"
                        disabled={sending}
                    />
                    <button
                        type="submit"
                        className="bg-indigo-700 text-white px-4 py-2 rounded-lg"
                        disabled={sending}
                    >
                        {sending ? 'Sending...' : 'Send'}
                    </button>
                </form>
            </div>}
        </>
    );
}
