// formData.js
const formData = {
  assignmentTypes: [
    'Admission Essay',
    'Annotated Bibliography',
    'Article Review',
    'Book/Movie Review',
    'Business Plan',
    'Business Proposal',
    'Capstone Project',
    'Case Study',
    'Course Outline',
    'Coursework',
    'Creative Writing',
    'Critical Thinking/Review',
    'Discussion Post',
    'Essay (Any Type)',
    'Homework',
    'Lab Report',
    'Letter/Memo',
    'Literature Review',
    'Marketing Plan',
    'Multiple Choice Questions',
    'Other',
    'Outline',
    'Personal Narrative',
    'Powerpoint with Speaker Notes',
    'Powerpoint',
    'Powerpoint', 
    'Presentation/Speech',
    'Reaction Paper',
    'Reflective Writing',
    'Report',
    'Research Paper',
    'SWOT Analysis',
    'Term Paper',
    'Thesis/Dissertation',
    'Word Problems',
    'Speech',
    
  ],
  subjects: [
    'Acccounting',
    'Advertising',
    'Agriculture',
    'Anatomy',
    'Ancient Literature',
    'Anthropology',
    'Architecture',
    'Art',
    'Astronomy',
    'Aviation',
    'Biology',
    'Business Management',
    'C++',
    'Calculus',
    'Chemistry',
    'Code',
    'Communications & Media',
    'Computer science',
    'Creative writing',
    'Dentistry',
    'Design & Modelling',
    'Drama & Theatre',
    'Economics',
    'Education',
    'Engineering',
    'English',
    'Ethics',
    'Excel',
    'Fashion',
    'Finance',
    'Forensic Sciences',
    'Gender Studies',
    'Geography',
    'Geometry',
    'Global Studies',
    'Healthcare',
    'History',
    'Hospitality',
    'Imigration & Citizenship',
    'IT',
    'Java',
    'Journalism',
    'Law',
    'Literature',
    'Logistics',
    'Marketing',
    'Mathematics',
    'Medicine & Health',
    'Music',
    'Nursing',
    'Phamarcology',
    'Philosophy',
    'Photography',
    'Political Science',
    'Psychiatry',
    'Public Relations',
    'Python',
    'Religion & Theory',
    'Social work',
    'SQL',
    'Statistics',
    'Speech',
    'Technology',
    'Urban & Environmental Planning',
    'Veterinary',
    'Web Design',
  ], 

  educationLevels: [
    'School',
    'College',
    'University',
    'Masters',
    'Doctorate',
  ],
  services: [
    'Writing',
    'Rewriting',
    'Editing',
    'Proofreading',
    'Problem Solving',
    'Calculations',
    // Add more services as needed
  ],
  spacingOptions: [
    'Double Spacing',
    'Single Spacing'
  ],
  languages: [
    'English (US)',
    'English (UK)',
    'Spanish',
    'French',
    'German',
    // Add more languages as needed
  ]

};

export default formData;
