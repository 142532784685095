import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Messages from './Messages';
import AccountList from './AccountList';
import { setDisplayChat, setNewOrderMessage } from '../../Redux/store';

const ChatPage = () => {
  const scroll = useRef();
  const userData = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const newOrderMessage = useSelector((state) => state.newOrderMessage);
  const displayChat = useSelector((state) => state.displayChat);
  const [messageDetails, setMessageDetails] = useState(null);


  useEffect(() => {
    if (newOrderMessage && newOrderMessage.email) {
      setMessageDetails({
        roomId: newOrderMessage.order_number.includes("-") ? newOrderMessage.order_number : newOrderMessage.order_number + " - " + newOrderMessage.username,
        recipient: newOrderMessage.email,
        username: newOrderMessage.username,
      });
    }
  }, [newOrderMessage, displayChat]);

  const cleanUp = () => {
    for (let i in window.msgIntervals) {
      clearInterval(window.msgIntervals[i]);
    }

    for (let i in window.accListIntervals) {
      clearInterval(window.accListIntervals[i]);
    }
    window.msgIntervals = [];
    window.accListIntervals = [];
    if (messageDetails) {
      setMessageDetails(null);
    }
    dispatch(setNewOrderMessage(null));
  }

  if (displayChat === 'none') {
    return <></>
  }



  const ChatHeading = () => {
    return (
      <div className='flex justify-between items-center border-b-2 py-4 px-2 border-gray-300'>
        < button
          onClick={() => {
            for (let i in window.msgIntervals) {
              clearInterval(window.msgIntervals[i]);
            }
            window.msgIntervals = [];
            setMessageDetails(null);
          }}
          className={"text-gray-600" + (messageDetails ? ' ' : ' hidden')}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 19l-7-7m0 0l7-7m-7 7h18"
            />
          </svg>
        </button >

        {messageDetails ? (
          <h2 className="text-md font-bold">
            {messageDetails ? `Messages for ${messageDetails.roomId
              }` : 'Select a recipient'}
          </h2>
        ) : (
          <h2 className="text-md font-bold">Chat</h2>
        )}
        < div
          className='flex justify-center items-center w-6 h-6 text-white bg-red-500 rounded-full cursor-pointer'
          onClick={() => {
            dispatch(setDisplayChat('none'));
            cleanUp();
          }}>
          x
        </div>

      </div>
    )
  }
  return (
    <div className={"overflow-hidden h-[60%] w-full lg:w-1/5 fixed bg-white top-0 lg:top-16 lg:right-4 border-2 border-gray-300 z-40"}>
      <div className="flex flex-col w-full h-full border-r border-gray-200">
        <div>
          <ChatHeading />
        </div>
        {messageDetails ? (
          <Messages
            messageDetails={messageDetails}
            userData={userData}
            scroll={scroll}
          />
        ) : (
          <AccountList
            setMessageDetails={setMessageDetails}
          />
        )}
      </div>
    </div>
  );
};

export default ChatPage;
