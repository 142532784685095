
const Testimonials = () => (
<section style={{background:'#f6f6f6', paddingLeft:'20px', paddingRight:'20px'}} className="my-0 dark:bg-gray-800 dark:text-gray-100">
	<div className="container flex flex-col items-center">
<h1 className="text-4xl font-extrabold text-center text-gray-800 pb-5 pt-10" id="team">
        What Our Clients Say About Us
       </h1>	</div>
 <div class="grid gap-0 mb-0 lg:mb-0 md:grid-cols-4">
  		<div  className="flex flex-col max-w-sm mx-4 my-16 shadow-md">
			<div style={{background:'#AD9551'}} className="px-4 py-12 rounded-t-lg sm:px-8 md:px-12 dark:bg-gray-900">
				<p className="relative px-6 py-1 text-lg italic text-center dark:text-gray-100">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="w-8 h-8 dark:text-violet-400">
						<path d="M232,246.857V16H16V416H54.4ZM48,48H200V233.143L48,377.905Z"></path>
						<path d="M280,416h38.4L496,246.857V16H280ZM312,48H464V233.143L312,377.905Z"></path>
					</svg>Their attention to detail, creativity, and timely delivery exceeded my expectations. I highly recommend them for anyone seeking top-notch freelance services
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="absolute right-0 w-8 h-8 dark:text-violet-400">
						<path d="M280,185.143V416H496V16H457.6ZM464,384H312V198.857L464,54.1Z"></path>
						<path d="M232,16H193.6L16,185.143V416H232ZM200,384H48V198.857L200,54.1Z"></path>
					</svg>
				</p>
			</div>
			<div className="flex flex-col items-center justify-center p-8 rounded-b-lg dark:bg-violet-400 dark:text-gray-900">
				<img src="https://source.unsplash.com/50x50/?portrait?1" alt="" className="w-16 h-16 mb-2 -mt-16 bg-center bg-cover rounded-full dark:bg-gray-500 dark:bg-gray-700" />
				<p className="text-xl font-semibold leadi">Anonymous</p>
				<p className="text-sm uppercase">November 2020</p>
			</div>
		</div>
<div  className="flex flex-col max-w-sm mx-4 my-16 shadow-md">
  			<div style={{background:'#AD9551'}} className="px-4 py-12 rounded-t-lg sm:px-8 md:px-12 dark:bg-gray-900">
				<p className="relative px-6 py-1 text-lg italic text-center dark:text-gray-100">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="w-8 h-8 dark:text-violet-400">
						<path d="M232,246.857V16H16V416H54.4ZM48,48H200V233.143L48,377.905Z"></path>
						<path d="M280,416h38.4L496,246.857V16H280ZM312,48H464V233.143L312,377.905Z"></path>
					</svg>Their expertise and dedication were evident throughout our collaboration. I'm extremely satisfied with the results and look forward to future projects together
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="absolute right-0 w-8 h-8 dark:text-violet-400">
						<path d="M280,185.143V416H496V16H457.6ZM464,384H312V198.857L464,54.1Z"></path>
						<path d="M232,16H193.6L16,185.143V416H232ZM200,384H48V198.857L200,54.1Z"></path>
					</svg>
				</p>
			</div>
			<div className="flex flex-col items-center justify-center p-8 rounded-b-lg dark:bg-violet-400 dark:text-gray-900">
				<img src="https://source.unsplash.com/50x50/?portrait?2" alt="" className="w-16 h-16 mb-2 -mt-16 bg-center bg-cover rounded-full dark:bg-gray-500 dark:bg-gray-700" />
				<p className="text-xl font-semibold leadi">Anonymous</p>
				<p className="text-sm uppercase">December 2022</p>
			</div>
		</div>
<div  className="flex flex-col max-w-sm mx-4 my-16 shadow-md">	
		<div style={{background:'#AD9551'}} className="px-4 py-12 rounded-t-lg sm:px-8 md:px-12 dark:bg-gray-900">
				<p className="relative px-6 py-1 text-lg italic text-center dark:text-gray-100">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="w-8 h-8 dark:text-violet-400">
						<path d="M232,246.857V16H16V416H54.4ZM48,48H200V233.143L48,377.905Z"></path>
						<path d="M280,416h38.4L496,246.857V16H280ZM312,48H464V233.143L312,377.905Z"></path>
					</svg>Their communication, problem-solving skills, and commitment to quality set them apart. I wouldn't hesitate to recommend their services. Will Use again and
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="absolute right-0 w-8 h-8 dark:text-violet-400">
						<path d="M280,185.143V416H496V16H457.6ZM464,384H312V198.857L464,54.1Z"></path>
						<path d="M232,16H193.6L16,185.143V416H232ZM200,384H48V198.857L200,54.1Z"></path>
					</svg>
				</p>
			</div>
			<div className="flex flex-col items-center justify-center p-8 rounded-b-lg dark:bg-violet-400 dark:text-gray-900">
				<img src="https://source.unsplash.com/50x50/?portrait?3" alt="" className="w-16 h-16 mb-2 -mt-16 bg-center bg-cover rounded-full dark:bg-gray-500 dark:bg-gray-700" />
				<p className="text-xl font-semibold leadi">Anonymous</p>
				<p className="text-sm uppercase">January 2024</p>
			</div>
		</div>
<div  className="flex flex-col max-w-sm mx-4 my-16 shadow-md">
  			<div style={{background:'#AD9551'}} className="px-4 py-12 rounded-t-lg sm:px-8 md:px-12 dark:bg-gray-900">
				<p className="relative px-6 py-1 text-lg italic text-center dark:text-gray-100">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="w-8 h-8 dark:text-violet-400">
						<path d="M232,246.857V16H16V416H54.4ZM48,48H200V233.143L48,377.905Z"></path>
						<path d="M280,416h38.4L496,246.857V16H280ZM312,48H464V233.143L312,377.905Z"></path>
					</svg>Their ability to grasp the project's requirements and deliver outstanding results makes them a valuable asset for any freelance job. I highly recommend
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="absolute right-0 w-8 h-8 dark:text-violet-400">
						<path d="M280,185.143V416H496V16H457.6ZM464,384H312V198.857L464,54.1Z"></path>
						<path d="M232,16H193.6L16,185.143V416H232ZM200,384H48V198.857L200,54.1Z"></path>
					</svg>
				</p>
			</div>
			<div className="flex flex-col items-center justify-center p-8 rounded-b-lg dark:bg-violet-400 dark:text-gray-900">
				<img src="https://source.unsplash.com/50x50/?portrait?4" alt="" className="w-16 h-16 mb-2 -mt-16 bg-center bg-cover rounded-full dark:bg-gray-500 dark:bg-gray-700" />
				<p className="text-xl font-semibold leadi">Anonymous</p>
				<p className="text-sm uppercase">May 2023</p>
			</div>
		</div>
	</div>
</section>
);

export default Testimonials;
