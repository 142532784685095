// // SurveyMonkeyEmbed.jsx

// import React, { useEffect } from 'react';

// const SurveyMonkeyEmbed = () => {
//   useEffect(() => {
//     // Embed SurveyMonkey script
//     (function(t, e, s, n) {
//       var o, a, c;
//       t.SMCX = t.SMCX || [];
//       if (!e.getElementById(n)) {
//         o = e.getElementsByTagName(s);
//         a = o[o.length - 1];
//         c = e.createElement(s);
//         c.type = "text/javascript";
//         c.async = !0;
//         c.id = n;
//         c.src = "https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd8bbFQyjNel4QIYvJx5zNMp2dqBm1_2B_2FGo1hB9nN4eML0.js";
//         a.parentNode.insertBefore(c, a);
//       }
//     })(window, document, "script", "smcx-sdk");
//   }, []);

//   return (
//     <div>
//       <p style={{font: '12px Helvetica, sans-serif', color: '#0000ff', textDecoration: 'underline', marginTop: '2rem'}}>
//         <a href="/" style={{color: 'blue'}}>Go back home</a>
//       </p>
//     </div>
//   );
// };

// export default SurveyMonkeyEmbed;



// import React, { useEffect } from 'react';

// const SurveyMonkeyEmbed = () => {
//   useEffect(() => {
//     // Embed SurveyMonkey script
//     (function(t, e, s, n) {
//       var o, a, c;
//       t.SMCX = t.SMCX || [];
//       if (!e.getElementById(n)) {
//         o = e.getElementsByTagName(s);
//         a = o[o.length - 1];
//         c = e.createElement(s);
//         c.type = "text/javascript";
//         c.async = !0;
//         c.id = n;
//         c.src = "https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd2pEuw6LlDDON80YkLLzdEyn7MzSFV0Kgifdj_2FyBMH5Z.js";
//         a.parentNode.insertBefore(c, a);
//       }
//     })(window, document, "script", "smcx-sdk");
//   }, []);

//   return (
//     <div>
//       <a 
//         style={{
//           font: '12px Helvetica, sans-serif',
//           color: '#999',
//           textDecoration: 'none',
//           marginTop: '2rem'
//         }} 
//         href="http://www.surveymonkey.com"
//       >
        
//       </a>
//     </div>
//   );
// };

// export default SurveyMonkeyEmbed;



// src/Components/Survey/Survey.js
import React, { useEffect } from 'react';

const Survey = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd2pEuw6LlDDON80YkLLzdEwOxvpt3vHd9h2FKAqA_2BWJr.js";
        script.async = true;
        script.id = "smcx-sdk";
        document.body.appendChild(script);

        return () => {
            document.getElementById('smcx-sdk')?.remove();
        };
    }, []);

    return (
        <div style={{ padding: '20px' }}>
            <h1>Feedback Survey</h1>
            <p>Please take a moment to fill out our survey and provide your valuable feedback.</p>
            <a style={{ font: '12px Helvetica, sans-serif', color: '#999', textDecoration: 'none' }} href="https://www.surveymonkey.com">
                
            </a>
        </div>
    );
};

export default Survey;
