import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Messages from './Messages';
import { setDisplaySupportChat, setNewSupportMessage } from '../../Redux/store';

const SupportChatPage = () => {
    const scroll = useRef();
    const userData = useSelector((state) => state.user);

    const dispatch = useDispatch();
    const newSupportMessage = useSelector((state) => state.newSupportMessage);
    const displaySupportChat = useSelector((state) => state.displaySupportChat);

    const [messageDetails, setMessageDetails] = useState(null);

    useEffect(() => {
        if (newSupportMessage && newSupportMessage.order_number === "SUPPORT") {
            setMessageDetails({
                roomId: "SUPPORT",
                recipient: process.env.REACT_APP_SUPPORT_EMAIL || "writerbeaz@gmail.com",
                username: newSupportMessage.username,
            });
        }
    }, [newSupportMessage, displaySupportChat]);

    const cleanUp = () => {
        for (let i in window.msgSupportIntervals) {
            clearInterval(window.msgSupportIntervals[i]);
        }
        window.msgSupportIntervals = [];
        dispatch(setNewSupportMessage(null));
    }

    if (displaySupportChat === 'none') {
        cleanUp();
        return <></>
    }



    const ChatHeading = () => {
        return (
            <div className='flex justify-between items-center border-b-2 py-4 px-2 border-gray-300'>
                <h2 className="text-md font-bold">
                    Support
                </h2>
                < div
                    className='flex justify-center items-center w-6 h-6 text-white bg-red-500 rounded-full cursor-pointer'
                    onClick={() => {
                        dispatch(setDisplaySupportChat('none'));
                        cleanUp();
                    }}>
                    x
                </div>
            </div>
        )
    };

    if (messageDetails?.roomId === 'SUPPORT') {
        return (
            <div className={"overflow-hidden w-full lg:w-1/5 fixed bg-white top-0 lg:top-[70%] lg:right-4 border-2 border-gray-300 z-40"}>
                <div className="flex flex-col w-full h-full border-r border-gray-200">
                    <div>
                        <ChatHeading />
                    </div>
                    <Messages
                        messageDetails={messageDetails}
                        userData={userData}
                        scroll={scroll}
                    />
                </div>
            </div>
        );
    }
};

export default SupportChatPage;
