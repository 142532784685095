// import React, { useEffect, useState } from 'react';
// import api from '../../../api/axiosConfig';
// import UserSidebar from './UserSidebar';
// import { FaDollarSign, FaClipboardList, FaHistory } from 'react-icons/fa';

// function UserHome() {
//   const [totalAmountEarned, setTotalAmountEarned] = useState(0);
//   const [orderCounts, setOrderCounts] = useState({});
//   const [transactions, setTransactions] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         console.log('Fetching data...');
//         setIsLoading(true);
//         const [totalAmountResponse, orderCountResponse, transactionResponse] = await Promise.all([
//           api.get('/users/user-totalamount/'),
//           api.get('/users/user-order-count/'),
//           api.get('/users/user-transaction-history/')
//         ]);

//         console.log('Total Amount Response:', totalAmountResponse.data);
//         console.log('Order Count Response:', orderCountResponse.data);
//         console.log('Transaction Response:', transactionResponse.data);

//         setTotalAmountEarned(totalAmountResponse.data.total_amount_earned);
//         setOrderCounts(orderCountResponse.data);
//         setTransactions(transactionResponse.data);
//         setIsLoading(false);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//         setError(`Failed to load data: ${error.message}`);
//         setIsLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   const formatCurrency = (value) => {
//     if (typeof value === 'number') {
//       return `$${value.toFixed(2)}`;
//     } else if (typeof value === 'string' && !isNaN(parseFloat(value))) {
//       return `$${parseFloat(value).toFixed(2)}`;
//     }
//     return '$0.00'; // Default value if not a number or valid string
//   };

//   if (isLoading) return <div className="flex justify-center items-center h-screen">Loading...</div>;
//   if (error) return <div className="flex justify-center items-center h-screen text-red-500">{error}</div>;

//   try {
//     console.log('Rendering component with data:', { totalAmountEarned, orderCounts, transactions });

//     return (
//       <div className="flex flex-col md:flex-row bg-gray-100 min-h-screen">
//         <UserSidebar />
//         <div className="flex-1 p-4 md:p-10 md:ml-64">
//           <h1 className="text-3xl font-bold mb-8">Dashboard</h1>
          
//           <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6 mb-8">
//             <DashboardCard 
//               title="Total Amount Earned" 
//               value={formatCurrency(totalAmountEarned)}
//               icon={<FaDollarSign className="text-green-500" />}
//             />
//           </div>

//           <div className="bg-white rounded-lg shadow p-4 md:p-6 mb-8">
//             <h2 className="text-xl font-semibold mb-4">Order Status</h2>
//             <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
//               <StatusItem title="Pending" value={orderCounts.pending || 0} color="yellow" />
//               <StatusItem title="In Progress" value={orderCounts.progress || 0} color="blue" />
//               <StatusItem title="Completed" value={orderCounts.completed || 0} color="green" />
//               <StatusItem title="Cancelled" value={orderCounts.cancelled || 0} color="red" />
//             </div>
//           </div>

//           <div className="bg-white rounded-lg shadow p-4 md:p-6">
//             <h2 className="text-xl font-semibold mb-4">Recent Transactions</h2>
//             {transactions && transactions.length > 0 ? (
//               transactions.map((transaction, index) => (
//                 <div key={index} className="border-b last:border-b-0 py-3">
//                   <p className="font-medium">{transaction.order?.orderTitle || 'Untitled Order'}</p>
//                   <p className="text-sm text-gray-600">Amount: {formatCurrency(transaction.amount)}</p>
//                 </div>
//               ))
//             ) : (
//               <p>No recent transactions.</p>
//             )}
//           </div>
//         </div>
//       </div>
//     );
//   } catch (renderError) {
//     console.error('Error rendering component:', renderError);
//     return (
//       <div className="flex justify-center items-center h-screen flex-col text-red-500">
//         <p>An error occurred while rendering the dashboard. Please try again.</p>
//         <p className="text-sm mt-2">Error details: {renderError.message}</p>
//       </div>
//     );
//   }
// }

// function DashboardCard({ title, value, icon }) {
//   return (
//     <div className="bg-white rounded-lg shadow p-6 flex items-center">
//       <div className="mr-4">{icon}</div>
//       <div>
//         <h3 className="text-lg font-semibold">{title}</h3>
//         <p className="text-2xl font-bold">{value}</p>
//       </div>
//     </div>
//   );
// }

// function StatusItem({ title, value, color }) {
//   const colorClasses = {
//     yellow: 'bg-yellow-100 text-yellow-800',
//     blue: 'bg-blue-100 text-blue-800',
//     green: 'bg-green-100 text-green-800',
//     red: 'bg-red-100 text-red-800',
//   };

//   return (
//     <div className={`${colorClasses[color]} rounded-lg p-4`}>
//       <h3 className="font-semibold">{title}</h3>
//       <p className="text-2xl font-bold">{value}</p>
//     </div>
//   );
// }

// export default UserHome;



import React, { useEffect, useState } from 'react';
import api from '../../../api/axiosConfig';
import UserSidebar from './UserSidebar';
import { FaDollarSign, FaClipboardList, FaHistory } from 'react-icons/fa';

function UserHome() {
  const [totalAmountEarned, setTotalAmountEarned] = useState(0);
  const [orderCounts, setOrderCounts] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Fetching data...');
        setIsLoading(true);
        const [totalAmountResponse, orderCountResponse, transactionResponse] = await Promise.all([
          api.get('/users/user-totalamount/'),
          api.get('/users/user-order-count/'),
          api.get('/users/user-transaction-history/')
        ]);

        console.log('Total Amount Response:', totalAmountResponse.data);
        setTotalAmountEarned(totalAmountResponse.data.total_amount_earned);
        setOrderCounts(orderCountResponse.data);
        setTransactions(transactionResponse.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(`Failed to load data: ${error.message}`);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
  };

  if (isLoading) return <div className="flex justify-center items-center h-screen">Loading...</div>;
  if (error) return <div className="flex justify-center items-center h-screen text-red-500">{error}</div>;

  try {
    console.log('Rendering component with data:', { totalAmountEarned, orderCounts, transactions });

    return (
      <div className="flex flex-col md:flex-row bg-gray-100 min-h-screen">
        <UserSidebar />
        <div className="flex-1 p-4 md:p-10 md:ml-64">
          <h1 className="text-3xl font-bold mb-8">Dashboard</h1>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6 mb-8">
            <DashboardCard 
              title="Total Amount Spent" 
              value={formatCurrency(totalAmountEarned)}
              icon={<FaDollarSign className="text-green-500" />}
            />
          </div>

          <div className="bg-white rounded-lg shadow p-4 md:p-6 mb-8">
            <h2 className="text-xl font-semibold mb-4">Order Status</h2>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              <StatusItem title="Pending" value={orderCounts.pending || 0} color="yellow" />
              <StatusItem title="In Progress" value={orderCounts.progress || 0} color="blue" />
              <StatusItem title="Completed" value={orderCounts.completed || 0} color="green" />
              <StatusItem title="Cancelled" value={orderCounts.cancelled || 0} color="red" />
            </div>
          </div>

          <div className="bg-white rounded-lg shadow p-4 md:p-6">
            <h2 className="text-xl font-semibold mb-4">Recent Transactions</h2>
            {transactions && transactions.length > 0 ? (
              transactions.map((transaction, index) => (
                <div key={index} className="border-b last:border-b-0 py-3">
                  <p className="font-medium">
                    Order Number: {transaction.order ? transaction.order.order_number : 'N/A'}
                  </p>
                  <p className="text-sm text-gray-600">
                    Amount: {formatCurrency(transaction.amount)}
                  </p>
                </div>
              ))
            ) : (
              <p>No recent transactions.</p>
            )}
          </div>
        </div>
      </div>
    );
  } catch (renderError) {
    console.error('Error rendering component:', renderError);
    return (
      <div className="flex justify-center items-center h-screen flex-col text-red-500">
        <p>An error occurred while rendering the dashboard. Please try again.</p>
        <p className="text-sm mt-2">Error details: {renderError.message}</p>
      </div>
    );
  }
}

function DashboardCard({ title, value, icon }) {
  return (
    <div className="bg-white rounded-lg shadow p-6 flex items-center">
      <div className="mr-4">{icon}</div>
      <div>
        <h3 className="text-lg font-semibold">{title}</h3>
        <p className="text-2xl font-bold">{value}</p>
      </div>
    </div>
  );
}

function StatusItem({ title, value, color }) {
  const colorClasses = {
    yellow: 'bg-yellow-100 text-yellow-800',
    blue: 'bg-blue-100 text-blue-800',
    green: 'bg-green-100 text-green-800',
    red: 'bg-red-100 text-red-800',
  };

  return (
    <div className={`${colorClasses[color]} rounded-lg p-4`}>
      <h3 className="font-semibold">{title}</h3>
      <p className="text-2xl font-bold">{value}</p>
    </div>
  );
}

export default UserHome;
