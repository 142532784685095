// import { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import api from '../../api/axiosConfig';
// import { Avatar } from "@material-tailwind/react";
// import avatar from '../../images/avatar.jpg';

// export default function AccountList({ setMessageDetails }) {
//     const [messageList, setMessageList] = useState([]);
//     const [intervalInitialized, setIntervalInitialized] = useState(false);
//     const [soundPlaying, setSoundPlaying] = useState(false); // state to track sound playback
//     const user = useSelector((state) => state.user);
//     const isAdmin = user && user.user_type === "Admin";

//     // Fetch accounts every 5 seconds, regardless of unread messages
//     useEffect(() => {
//         if (!intervalInitialized) {
//             const startFetching = async () => {
//                 await fetchAccounts(); // Initial fetch

//                 // Set interval to fetch every 5 seconds
//                 let xid = setInterval(() => {
//                     fetchAccounts();
//                 }, 5000);

//                 try {
//                     if (!window.accListIntervals) {
//                         window.accListIntervals = [];
//                     }
//                     window.accListIntervals.push(xid);
//                 } catch (error) {
//                     window.accListIntervals = [xid];
//                 }
                
//                 setIntervalInitialized(true);
//             };

//             startFetching();
//         }
//     }, [intervalInitialized]);

//     const fetchAccounts = async () => {
//         try {
//             const response = await api.get('/api/accounts-list/');
//             if (response.data) {
//                 setMessageList(response.data);
//             }
//         } catch (error) {
//             console.error('Error fetching user data:', error);
//         }
//     };

//     // Check for unread messages separately from fetching
//     useEffect(() => {
//         const playNotificationSound = () => {
//             const audio = new Audio('/notification.mp3');
//             audio.play();
//         };

//         const checkUnreadMessages = () => {
//             if (messageList.some(message => unreadMessageCount(message) > 0)) {
//                 if (!soundPlaying) {
//                     playNotificationSound();
//                     setSoundPlaying(true); // set sound playing to true
//                 }
//             } else {
//                 setSoundPlaying(false); // reset sound playing if no unread messages
//             }
//         };

//         // Check for unread messages every 5 seconds
//         const checkInterval = setInterval(checkUnreadMessages, 5000);

//         return () => clearInterval(checkInterval);
//     }, [messageList, soundPlaying]); // messageList dependency added

//     const unreadMessageCount = (message) => {
//         if (user && user.user_type === "Admin") {
//             return 0;
//         }
//         if (user && user.user_type === "User") {
//             return message?.client_unread_count;
//         }
//         if (user && user.user_type === "Tutor") {
//             return message?.tutor_unread_count;
//         }
//     };

//     return (
//         <ul className="h-full overflow-y-auto">
//             {Array.isArray(messageList) && messageList.length > 0 ? (
//                 messageList.map((message, ind) => (
//                     <div key={ind}>
//                         {message.order_number === "SUPPORT" ? <></> :
//                             <li
//                                 key={ind}
//                                 className={`flex items-center py-3 px-4 cursor-pointer border-b-2` + (unreadMessageCount(message) > 0 && !isAdmin ? " bg-gray-200" : "")}
//                                 onClick={() => {
//                                     setMessageDetails({
//                                         roomId: isAdmin ? message.username : message.order_number,
//                                         recipient: message.email,
//                                     });
//                                 }}
//                             >
//                                 <div className="flex-shrink-0 mr-3 mt-1">
//                                     <Avatar
//                                         src={avatar}
//                                         alt="User Avatar"
//                                         size="md"
//                                         className="rounded-full w-12 h-12 object-cover"
//                                     />
//                                 </div>
//                                 <div className="flex-grow">
//                                     <span className="text-start ms-3 text-md">
//                                         {isAdmin && message.username}
//                                         {!isAdmin && (message.order_number === "SUPPORT" ? "SUPPORT" : "Order " + message.order_number)}
//                                     </span>
//                                 </div>
//                                 {unreadMessageCount(message) > 0 && !isAdmin && <span className='px-1.5 py-0.25 rounded-full bg-blue-500 text-white text-sm ml-2'>{unreadMessageCount(message)}</span>}
//                             </li>
//                         }
//                     </div>
//                 ))
//             ) : (
//                 <p className="p-4 text-gray-500">No recipients found.</p>
//             )}
//         </ul>
//     );
// }




import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import api from '../../api/axiosConfig';
import { Avatar } from "@material-tailwind/react";
import avatar from '../../images/avatar.jpg';

export default function AccountList({ setMessageDetails }) {
    const [messageList, setMessageList] = useState([]);
    const [soundPlaying, setSoundPlaying] = useState(false);
    const user = useSelector((state) => state.user);
    const isAdmin = user && user.user_type === "Admin";

    // Fetch accounts and set interval
    useEffect(() => {
        const fetchAccounts = async () => {
            try {
                const response = await api.get('/api/accounts-list/');
                setMessageList(response.data);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchAccounts(); // Initial fetch

        const intervalId = setInterval(() => {
            fetchAccounts();
        }, 5000);

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, [isAdmin]);

    // Check for unread messages and play notification sound
    useEffect(() => {
        const playNotificationSound = () => {
            const audio = new Audio('/notification.mp3');
            audio.play();
        };

        const checkUnreadMessages = () => {
            if (messageList.some(message => unreadMessageCount(message) > 0)) {
                if (!soundPlaying) {
                    playNotificationSound();
                    setSoundPlaying(true); 
                }
            } else {
                setSoundPlaying(false); 
            }
        };

        const checkIntervalId = setInterval(checkUnreadMessages, 5000);

        return () => clearInterval(checkIntervalId); // Cleanup on unmount
    }, [messageList, soundPlaying]);

    const unreadMessageCount = (message) => {
        if (user && user.user_type === "Admin") {
            return 0;
        }
        if (user && user.user_type === "User") {
            return message?.client_unread_count;
        }
        if (user && user.user_type === "Tutor") {
            return message?.tutor_unread_count;
        }
    };

    const getLastMessagePreview = (orderNumber) => {
        const message = messageList.find(m => m.order_number === orderNumber);
        if (message && message.last_message) {
            return message.last_message;
        }
        return 'No messages yet.';
    };

    return (
        <ul className="h-full overflow-y-auto">
            {Array.isArray(messageList) && messageList.length > 0 ? (
                messageList.map((message, ind) => (
                    <div key={ind}>
                        {message.order_number === "SUPPORT" ? null :
                            <li
                                key={ind}
                                className={`flex flex-col items-start py-3 px-4 cursor-pointer border-b-2 ${unreadMessageCount(message) > 0 && !isAdmin ? " bg-gray-200" : ""}`}
                                onClick={() => {
                                    setMessageDetails({
                                        roomId: isAdmin ? message.username : message.order_number,
                                        recipient: message.email,
                                    });
                                }}
                            >
                                <div className="flex items-center w-full">
                                    <div className="flex-shrink-0 mr-3 mt-1">
                                        <Avatar
                                            src={avatar}
                                            alt="User Avatar"
                                            size="md"
                                            className="rounded-full w-12 h-12 object-cover"
                                        />
                                    </div>
                                    <div className="flex-grow">
                                        <span className="text-start ms-3 text-md">
                                            {isAdmin && message.username}
                                            {!isAdmin && (message.order_number === "SUPPORT" ? "SUPPORT" : "Order " + message.order_number)}
                                        </span>
                                    </div>
                                    {unreadMessageCount(message) > 0 && !isAdmin && <span className='px-1.5 py-0.25 rounded-full bg-blue-500 text-white text-sm ml-2'>{unreadMessageCount(message)}</span>}
                                </div>
                                <div className="text-gray-600 text-sm mt-2">
                                    {getLastMessagePreview(message.order_number)}
                                </div>
                            </li>
                        }
                    </div>
                ))
            ) : (
                <p className="p-4 text-gray-500">No recipients found.</p>
            )}
        </ul>
    );
}
